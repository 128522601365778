<template>
  <v-dialog
    v-model="show"
    width="550"
  >
    <v-card>
      <v-toolbar
        color="#333"
        dark
      >
        <div class="text-h5">
          Подтвердить действие
        </div>
      </v-toolbar>
      <div class="pa-6">
        <div class="text-body-1 black--text">
          Применить ко всем товарам?
        </div>
      </div>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#333"
          text
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#5cb7b1"
          dark
          @click="submit"
        >
          Подтвердить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {copyToAllSucceed, submitCopyToAll} from "@/events/common";
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";
import {getStoreDocTypeByUrl} from "@/helpers/common";
import {convertEmptyStringsToNull} from "@/helpers/objects";

export default {
  mixins: [closeOnEscapeMixin],
  data: () => ({
    show: false,
    module: null,
    waresParams: {
      field: null,
      value: null
    },
  }),
  computed: {
    requests() {
      if (this.module) {
        return this.$store.getters[`${this.module}/getRequests`]
      }
      return null
    },
    selected() {
      if (this.module) {
        return this.$store.getters[`${this.module}/getSelected`]
      }
      return null
    },
    updateInShipment(){
      if(this.selected){
        return 'ware_shipments' in this.selected
      }
      return false
    }
  },
  created() {
    this.setModule()
    submitCopyToAll.subscribe(this.onModal)
  },
  beforeDestroy() {
    submitCopyToAll.unsubscribe()
  },
  methods: {
    setModule() {
      const [, module] = this.$route.path.split('/')
      this.module = getStoreDocTypeByUrl(module)
    },
    onModal({field, value}) {
      if (Array.isArray(field)) {
        let prepared = {}
        field.forEach((key, index) => {
          prepared[key] = value[index]
        })
        this.waresParams = {
          field,
          value: prepared,
        }
      } else {
        this.waresParams = {
          field, value
        }
      }
      this.show = true
    },
    getText(err) {
      return typeof err === 'object' && 'response' in err ? err.response : (err || "Ошибка")
    },
    success() {
      this.$snackbar({text: 'Действие применено', top: false, right: false})
      this.close()
    },
    error(err) {
      this.$snackbar({text: this.getText(err), top: false, right: false, color: 'red'})
      throw err
    },
    close() {
      this.show = false
    },
    getActionName(){
      return this.updateInShipment ? 'applyToAllInShipment':'applyToAll'
    },
    updateMultipleFields(){
      const ACTION_NAME = this.getActionName()
      const values = {}
      this.waresParams.field.forEach(key => {
        values[key] = this.waresParams.value[key]
      })
      const payload = convertEmptyStringsToNull({id: this.selected.id, values})
      return this.$store.dispatch(`${this.module}/${ACTION_NAME}`,payload)
        .then(this.success)
        .catch(this.error)
    },
    updatePrecedingDocuments(){
      const payload = convertEmptyStringsToNull(this.waresParams.value)
      return this.$store.dispatch(`${this.module}/copyPrecedingDocumentsToAll`, payload)
        .then(this.success)
        .catch(this.error)
    },
    updateSingleField(){
      const ACTION_NAME = this.getActionName()
      const payload = convertEmptyStringsToNull({
        id: this.selected.id,
        values: {
          [this.waresParams.field]: this.waresParams.value
        }
      })
      return this.$store.dispatch(`${this.module}/${ACTION_NAME}`, payload)
        .then(this.success)
        .catch(this.error)
    },
    // обновление блока "Предшествующий документ" если нет вложенности с ware_shipments
    updateAllWaresWithoutWareShipments(){
      const payload = convertEmptyStringsToNull(this.waresParams.value)
      return this.$store.dispatch(`${this.module}/copyPrecedingDocumentsToAllWithoutWareShipments`, payload)
        .then(this.success)
        .catch(this.error)
    },
    isClearCustomsCostEvent(){
      return this.waresParams.field === 'customs_cost' && this.waresParams.value === null
    },
    updateSelectedField(){
      if (Array.isArray(this.waresParams.field)) {
        return this.updateMultipleFields()
      } else {
        if (this.waresParams.field === 'preceding_documents') {
          if (!this.updateInShipment) {
            return this.updateAllWaresWithoutWareShipments();
          } else return this.updatePrecedingDocuments()
        } else {
          return this.updateSingleField()
        }
      }
    },
    notifyOnUpdateComplete({success, customsCostCleared}){
      copyToAllSucceed.trigger({success, customsCostCleared})
    },
    submit() {
      this.updateSelectedField()
        .then(() => this.notifyOnUpdateComplete({
          success:true,
          customsCostCleared: this.isClearCustomsCostEvent()
        }))
        .catch(() => this.notifyOnUpdateComplete({
          success:false,
          customsCostCleared: this.isClearCustomsCostEvent()
        }))

    }
  }

}
</script>
